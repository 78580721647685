import { intl } from "@tci18n/vue2";import Main from '@/view/public/main';

// 组织
export default [
{
  path: '/organizationManager',
  name: 'hrms_organization',
  redirect: 'organization',
  component: Main,
  meta: {
    title: intl.$t("组织")
  },
  icon: 'hrms_organization',
  children: [
  {
    path: '/organization',
    name: 'organization',
    meta: {
      title: intl.$t("组织"),
      menuName: 'hrms_organization'
    },
    component: () => import('./index.vue')
  }]

}];