<template>
<div class="password-container">
    <div class="title">{{$t('修改密码')}}</div>
    <el-form ref="pwdForm" class="lz-form--block" :model="pwdForm" :rules="rules">
      <div class="section">
        <div class="subtitle">{{$t('原密码：')}}</div>
        <el-form-item prop="oldPassword">
          <div class="input-wrapper mt-10 display__flex align_items__center">
            <el-input class="prefix-input" v-model="pwdForm.oldPassword" :type="pwdForm.oldPwdCipher ? 'password' : 'text'" clearable :placeholder="$t('请输入原密码')" @clear="pwdForm.oldPassword = ''"></el-input>
            <div class="suffix-icon" @click="pwdForm.oldPwdCipher = !pwdForm.oldPwdCipher">
              <i :class="['iconfont', pwdForm.oldPwdCipher ? 'lzicon-eye_off' : 'lzicon-eye_on']"></i>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="section">
        <div class="subtitle">{{$t('新密码：')}}</div>
        <el-form-item prop="newPassword">
          <div class="input-wrapper mt-10 display__flex align_items__center">
            <el-input class="prefix-input" v-model="pwdForm.newPassword" :type="pwdForm.newPwdCipher ? 'password' : 'text'" clearable :placeholder="$t('请输入新密码')" @clear="pwdForm.newPassword = ''"></el-input>
            <div class="suffix-icon" @click="pwdForm.newPwdCipher = !pwdForm.newPwdCipher">
              <i :class="['iconfont', pwdForm.newPwdCipher ? 'lzicon-eye_off' : 'lzicon-eye_on']"></i>
            </div>
          </div>
        </el-form-item>
      </div>
      <div class="section">
        <div class="subtitle">{{$t('重复密码：')}}</div>
        <el-form-item prop="newPassword2">
          <div class="input-wrapper mt-10 display__flex align_items__center">
            <el-input class="prefix-input" v-model="pwdForm.newPassword2" :type="pwdForm.newPwdCipher2 ? 'password' : 'text'" clearable :placeholder="$t('请再次输入新密码')" @clear="pwdForm.newPassword2 = ''"></el-input>
            <div class="suffix-icon" @click="pwdForm.newPwdCipher2 = !pwdForm.newPwdCipher2">
              <i :class="['iconfont', pwdForm.newPwdCipher2 ? 'lzicon-eye_off' : 'lzicon-eye_on']"></i>
            </div>
          </div>
        </el-form-item>
      </div>
    </el-form>

    <div class="lz-dialog__footer password-footer">
      <el-button type="primary" size="small" class="lz-dialog__footer__button confirm" @click="handleConfirmClick">{{$t('确定')}}</el-button>
      <el-button size="small" class="lz-dialog__footer__button cancel" @click="closeModal()">{{$t('取消')}}</el-button>
    </div>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import systemHelper from '@/libs/system';
import basicHelper from '@/view/common/basic';
import { regexPwd } from '@/libs/regexUtil';

export default {
  name: 'ChangePassword',
  data() {
    return {
      pwdForm: {
        // 原密码
        oldPassword: '',
        // 是否显示密文
        oldPwdCipher: true,

        // 新密码
        newPassword: '',
        newPwdCipher: true,

        // 重复新密码
        newPassword2: '',
        newPwdCipher2: true
      },
      rules: {
        oldPassword: [
        { required: true, message: intl.$t("请输入原密码"), trigger: 'blur' }],

        newPassword: [
        { required: true, trigger: 'blur', validator: regexPwd }],

        newPassword2: [
        { required: true, trigger: 'blur', validator: (rule, value, callback) => {
            if (rule.required && value.trim().length === 0) {
              callback(new Error(intl.$t("请输入重复密码")));
            } else if (this.pwdForm.newPassword.trim().length > 0 && this.pwdForm.newPassword.trim() !== value.trim()) {
              callback(new Error(intl.$t("新密码与重复密码不一致")));
            } else {
              callback();
            }
          }
        }]

      }
    };
  },
  methods: {
    /**
     * 设置视图 loading 状态
     */
    viewLoading(loading) {
      this.$emit('loading', loading);
    },

    /**
     * 关闭弹窗
     */
    closeModal() {
      this.$emit('input', false);
    },

    /**
     * 修改密码后处理逻辑
     */
    processAfterChangePassword() {
      this.$emit('input', false);
      // 延迟退出以显示 Notice
      setTimeout(() => {
        systemHelper.loginByOAuth();
      }, 1500);
    },

    /**
     * 提交密码修改
     */
    submitPassword() {
      let params = {
        chainId: this.$store.getters['chainId'],
        originalPwd: this.pwdForm.oldPassword,
        newPwd: this.pwdForm.newPassword
      };
      this.viewLoading(true);
      basicHelper.changePassword(params).then((res) => {
        this.viewLoading(false);
        this.$notice.success(intl.$t("密码修改成功！"));
        this.processAfterChangePassword();
      }).catch((error) => {
        this.viewLoading(false);
        this.$notice.error(error.message);
      });
    },

    /**
     * 确认按钮点击事件
     */
    handleConfirmClick() {
      this.$refs['pwdForm'].validate((valid) => {
        if (valid) {
          this.submitPassword();
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/const";

  .password-container {
    padding: 8px 4px 4px;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 24px;
    }

    .section:not(:first-of-type) {
      margin-top: 24px;
    }

    .subtitle {
      font-size: 16px;
      line-height: 1;
    }

    .input-wrapper {
      height: 40px;
      border: 1px solid #DEE3EE;
      border-radius: 2px;

      .prefix-input {
        width: calc(100% - 32px) !important;
      }

      .prefix-input .el-input__inner {
        border: none;
      }

      .suffix-icon {
        width: 32px;
        height: 32px;
        text-align: center;
      }

      .suffix-icon i {
        color: $text-color;
        vertical-align: middle;
      }
    }

    .password-footer {
      text-align: right;
      padding: 0 !important;
    }
  }
</style>
