<template>
<el-submenu :index="menuData.name">
    <template slot="title">
      <lz-icon v-if="level === 1" class="lz-menu-icon" type="iconfont" :name="getMenuItemIcon(menuData)" :size="16"></lz-icon>
      <span class="lz-menu-title">{{getMenuItemTitle(menuData)}}</span>
    </template>

    <template v-for="childMenuData in menuData.children">
      <!-- 存在子菜单场景（层级暂支持到3级） -->
      <template v-if="childMenuData.children && childMenuData.children.length>0 && level<2">
        <lz-sub-menu :key="`menu-${getMenuItemName(childMenuData)}`" :class="['lz-submenu', `level-${level+1}`]" :menu-data="childMenuData" :level="level+1"></lz-sub-menu>
      </template>

      <!-- 无子菜单场景 -->
      <template v-else>
        <el-menu-item :key="`menu-${getMenuItemName(childMenuData)}`" :class="['lz-menu-item', `level-${level+1}`]" :index="getMenuItemName(childMenuData)">
          <lz-icon v-if="level === 1" class="lz-menu-icon" type="iconfont" :name="getMenuItemIcon(childMenuData)" :size="16"></lz-icon>
          <span class="lz-menu-title">{{getMenuItemTitle(childMenuData)}}</span>
        </el-menu-item>
      </template>
    </template>
  </el-submenu>
</template>

<script>
import mixin from './mixin'

/**
 * 子菜单视图
 */
export default {
  name: 'LzSubMenu',
  props: {
    // 子菜单项数据源
    menuData: Object,

    // 层级
    level: Number
  },
  mixins: [mixin],
}
</script>
