<template>
<el-dialog :visible="visible" :width="width" top="0" append-to-body :close-on-click-modal="maskClosable" :close-on-press-escape="maskClosable" class="vertical-center" :custom-class="'lz-dialog lz-dialog-'+name" @close="handleDialogClose">
    <div slot="title" v-if="!headerHide" class="lz-dialog__header">
      <span>{{title}}</span>
    </div>

    <template>
      <slot name="body" v-if="customBody"></slot>
      <div v-else class="lz-dialog__body">
        <div class="lz-dialog__title">
          <i class="lz-dialog__title__icon element-icons el-icon-warning"></i><span :class="['lz-dialog__body__title', { 'bold': content }]">{{title}}</span>
        </div>
        <div v-if="content" class="lz-dialog__body__content">{{content}}</div>
      </div>
    </template>

    <template slot="footer" v-if="!footerHide">
      <slot name="footer" v-if="customFooter"></slot>
      <div v-else class="lz-dialog__footer">
        <el-button type="primary" size="small" class="lz-dialog__footer__button confirm" @click="handleDialogConfirm()">{{confirmButtonText}}</el-button>
        <el-button size="small" class="lz-dialog__footer__button cancel" @click="handleDialogCancel()">{{cancelButtonText}}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { intl } from "@tci18n/vue2"; /**
 * 封装自定义 Dialog 组件
 */
export default {
  name: 'LzDialog',
  model: {
    // 自定义用于 v-model 的 prop 和事件
    prop: 'visible',
    event: 'change'
  },
  props: {
    // dialog 是否显示
    visible: {
      type: Boolean,
      default: false
    },

    // dialog 标识
    name: {
      type: String,
      default: ''
    },

    // dialog 标题
    title: {
      type: String,
      default: ''
    },

    // dialog 内容
    content: undefined,

    // 确定、取消按钮文案
    confirmButtonText: {
      type: String,
      default: intl.$t("确定")
    },

    cancelButtonText: {
      type: String,
      default: intl.$t("取消")
    },

    // dialog 宽度
    width: {
      type: String,
      default: '430px'
    },

    // 是否允许点击遮罩层关闭
    maskClosable: {
      type: Boolean,
      default: true
    },

    // 是否显示 header
    headerHide: {
      type: Boolean,
      default: true
    },

    // 是否显示 footer
    footerHide: {
      type: Boolean,
      default: false
    },

    // 是否显示 loading 视图
    loadingEnable: Boolean,
    loading: Boolean
  },
  data() {
    return {
      // 是否自定义 body
      customBody: false,
      // 是否自定义 footer
      customFooter: false,

      // loading 对象
      loadingIns: null
    };
  },
  watch: {
    loading(newVal) {
      if (this.loadingEnable) {
        this.setLoading(newVal);
      }
    }
  },
  mounted() {
    this.customBody = this.$slots.body !== undefined;
    this.customFooter = this.$slots.footer !== undefined;
  },
  methods: {
    /**
     * 设置 loading
     * @param { Boolean } loading 是否是 loading 状态
     */
    setLoading(loading) {
      if (loading) {
        this.loadingIns = this.$loading({
          lock: true,
          target: this.name ? `.lz-dialog-${this.name}` : '.lz-dialog'
        });
      } else if (!loading && this.loadingIns) {
        this.loadingIns.close();
        this.loadingIns = null;
      }
    },

    closeDialog() {
      // 修改 v-model prop 值
      this.$emit('change', false);
    },

    // 关闭事件
    handleDialogClose() {
      this.$emit('close');
      this.closeDialog();
    },

    /**
     * 确定按钮点击事件
     */
    handleDialogConfirm() {
      this.$emit('confirm');
    },

    /**
     * 取消按钮点击事件
     */
    handleDialogCancel() {
      this.$emit('cancel');
      this.closeDialog();
    }
  }
};
</script>

<style lang="scss">
@import "@/style/const";

  .v-modal {
    background-color: #000512 !important;
  }

  .el-dialog__wrapper.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lz-dialog {
    $_border-color: $border-color;
    $_dividing-line-color: $dividing-line-color;
    $_theme-color: $theme-color;
    $_text-color: $text-color;
    $_warning-color: $warning-color;

    .el-dialog__header {
      padding: 16px 0 0;
    }

    .el-dialog__headerbtn {
      font-size: 20px;
      top: 9px;
      right: 16px;
    }

    .el-dialog__body {
      padding: 16px;
    }

    .el-dialog__footer {
      padding-top: 16px;
    }

    .lz-dialog__header {
      padding: 0 20px 16px;
      border-bottom: 1px solid $_dividing-line-color;
      font-weight: bold;
      line-height: 1;
    }

    .lz-dialog__body {
      color: $_text-color;
      margin: 0 0 0 16px;
      
      .lz-dialog__title {
        line-height: 1;
      }

      .lz-dialog__title__icon {
        margin-right: 17px;
        vertical-align: middle;
        color: $_warning-color;
        font-size: 24px;
      }

      .lz-dialog__body__title {
        font-size: 16px;
        vertical-align: middle;
      }

      .lz-dialog__body__title.bold {
        font-weight: bold;
      }

      .lz-dialog__body__content {
        font-size: 14px;
        margin-left: 40px;
        margin-top: 12px;
      }
    }

    .lz-dialog__footer {
      .lz-dialog__footer__button {
        min-width: 65px;
        padding: 8px 15px;
        font-size: 14px;
        border-radius: 2px;
      }

      .lz-dialog__footer__button.confirm {
        color: #FFF;
        background-color: $_theme-color;
        border-color: $_theme-color;
      }

      .lz-dialog__footer__button.cancel {
        border: 1px solid $_border-color;
      }
    }

    .lz-dialog__footer button + button {
      margin-left: 8px;
    }
  }
</style>
