import { intl } from "@tci18n/vue2";import Main from '@/view/public/main';

// 设置页
export default [
{
  path: '/settingManager',
  name: 'hrms_setting',
  redirect: 'setting',
  component: Main,
  meta: {
    title: intl.$t("设置")
  },
  icon: 'hrms_setting',
  children: [
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: intl.$t("设置"),
      menuName: 'hrms_setting'
    },
    component: () => import('./setting.vue')
  }]

}];