<template>
<div class="lz-pagination">
    <div v-if="showTotal" class="lz-pagination__desc">{{$t('页]')}}</div>
    <el-pagination class="lz-pagination__content" :total="total" :page-size="pageSize" :current-page="currentPage" :prev-text="$t('上一页')" :next-text="$t('下一页')" :page-sizes="[10, 20, 50]" layout="prev, pager, next, jumper" @size-change="handlePaginationSizeChange" @current-change="handlePaginationOnChange"></el-pagination>
  </div>
</template>

<script>
/**
 * 封装分页组件
 */
export default {
  name: 'LzPagination',
  props: {
    // 总条目数
    total: {
      type: Number,
      default: 0
    },

    // 总页数
    pageCount: {
      type: Number,
      default: 0
    },

    // 每页显示条数
    pageSize: {
      type: Number,
      default: 0
    },
    
    // 当前页数
    currentPage: {
      type: Number,
      default: 0
    },

    // 是否展示 total
    showTotal: {
      type: Boolean,
      default: true
    },

    // 是否展示 jumper
    showJumper: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * 每页条数改变回调
     * @param { Number } size 每页条数
     */
    handlePaginationSizeChange (size) {
      this.$emit('size-change', size)
    },

    /**
     * 页码改变回调
     * @param { Number } current 新页码
     */
    handlePaginationOnChange (current) {
      this.$emit('on-change', current);
    }
  }
}
</script>

<style>
.lz-pagination {
    font-size: 12px;
    height: 32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .lz-pagination__desc {
    color: #AAB1BD;
    letter-spacing: 0;
    line-height: 12px;
    margin-right: 20px;
  }

  .lz-pagination .el-pagination {
    padding: 0;
    font-weight: normal;
  }

  .lz-pagination .el-pager li {
    min-width: 24px;
    height: 24px;
    line-height: 22px;
    border: 1px solid #DEE3EE;
    border-radius: 2px;
    font-size: 14px;
    font-weight: normal;
  }

  .lz-pagination .el-pager li + li {
    margin-left: 10px;
  }

  .lz-pagination .el-pager li.active {
    border-color: #497CF6;
  }

  .lz-pagination .el-pager li.active + li {
    border-left: 1px solid #DEE3EE;
  }

  .lz-pagination .el-pagination__jump,
  .lz-pagination .el-pagination button,
  .lz-pagination .el-pagination button span {
    height: 24px !important;
    line-height: 24px !important;
  }

  .lz-pagination .el-pagination__jump {
    margin-left: 16px;
  }

  .lz-pagination .el-pagination__editor,
  .lz-pagination .el-pagination__editor.el-input .el-input__inner {
    height: 24px;
    vertical-align: top;
  }

  .lz-pagination .el-pagination__sizes {
    margin: 0 0 0 10px;
  }

  .lz-pagination .el-pagination .el-select .el-input {
    width: 78px;
  }
</style>
